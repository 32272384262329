import { defineMessages, useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import Headline from '../../components/Headline';
import Layout from '../../components/Layout';
import SEO, { createAlternateLinks } from '../../components/Seo';
import Termly from '../../components/Termly';

const messages = defineMessages({
  title: {
    id: 'privacyPolicy.title',
    defaultMessage: 'Privacy Policy',
  },
});

const PrivacyPolicy = ({
  location: { pathname },
  pageContext: {
    intl: { originalPath },
  },
}) => {
  const { locale, formatMessage } = useIntl();
  const alternateLinks = createAlternateLinks(originalPath);

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage(messages.title)}
        pathname={pathname}
        link={alternateLinks}
        robots={[`noindex`, `nofollow`]}
      />
      <Layout originalPath={originalPath}>
        <Headline title={formatMessage(messages.title)} />
        <Termly dataId="2b67a76c-6e31-4cf1-a83f-f22cd5b7099a" />
      </Layout>
    </>
  );
};

PrivacyPolicy.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default PrivacyPolicy;
